import { IWixWindow } from '@wix/yoshi-flow-editor';
import type {
  ListVideosResult,
  VideoItem,
  Channel,
} from '@wix/wix-vod-api/public';
import type { IHttpClient } from '@wix/http-client';
import { getPublicApi } from '../../api/public';
import { MEDIA_TYPES, URL_TYPES } from '@wix/wix-vod-constants/common';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

interface BaseWidgetData {
  __TRANSLATIONS__: Record<string, string>;
}

interface BaseWidgetDataV2 extends BaseWidgetData {
  __CHANNEL__: Channel;
}

interface SingleVideoWidgetDataV2 extends BaseWidgetDataV2 {
  isSingleVideo: true;
  __SINGLE_VIDEO__: VideoItem | undefined;
}

interface ChannelWidgetDataV2 extends BaseWidgetDataV2 {
  isSingleVideo: false;
  __VIDEOS__: ListVideosResult;
}

export type WidgetDataV3V2Mapping =
  | SingleVideoWidgetDataV2
  | ChannelWidgetDataV2;

interface GetWidgetDataQueryParams {
  channelId: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount: number;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const getWidgetDataV3V2Mapping = async ({
  queryParams,
  translations,
}: GetWidgetDataOptions): Promise<WidgetDataV3V2Mapping> => {
  const { channelId, videoId, videosCount } = queryParams;
  const publicApi = getPublicApi();
  const channel = await publicApi.channelService.getInfo(channelId);

  // const withPlayInfo = isWixCodeSEOEnabled();
  // for now it was decided not to send the contentUrl value
  // since it's a url with a signed token which expires and won't be available for the bot
  // https://wix.slack.com/archives/C01HMHGN26S/p1691660291999629?thread_ts=1675068385.226839&cid=C01HMHGN26S
  // and requesting with play info is not used by application but makes the request significantly longer
  const withPlayInfo = false;

  const baseWidgetData: BaseWidgetDataV2 = {
    __TRANSLATIONS__: translations,
    __CHANNEL__: channel,
  };

  if (videoId) {
    const requestOptions = withPlayInfo
      ? {
          params: {
            with_play_info: true,
          },
        }
      : undefined;
    const singleVideo = (
      await publicApi.channelVideos.getChannelVideoById(
        channelId,
        videoId,
        requestOptions,
      )
    ).data;
    return {
      ...baseWidgetData,
      __SINGLE_VIDEO__: singleVideo,
      isSingleVideo: true,
    };
  }

  const videos = channel.externalId
    ? await publicApi.youtubeVideosService.listYoutubeChannelVideos(
        {
          externalId: channel.externalId,
          urlType: channel.urlType as URL_TYPES.CHANNEL | URL_TYPES.PLAYLIST,
        },
        { paging: { size: videosCount } },
      )
    : await publicApi.channelVideos.listChannelVideos({
        channelId,
        paging: {
          size: videosCount,
        },
        mediaType: MEDIA_TYPES.SECURE_VIDEO,
        withPlayInfo,
      });
  return {
    ...baseWidgetData,
    __VIDEOS__: videos ?? [],
    isSingleVideo: false,
  };
};

export type WidgetData = WidgetDataV3V2Mapping;

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData> => getWidgetDataV3V2Mapping(options);
